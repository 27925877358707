html {
  font-size: 62.5%;
  scrollbar-color: #00000000;
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent;
}

body {
  background-color: #1c1b21;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrapper {
  max-width: 1100px;
  margin: 0 auto;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 1024px) {
  html {
    font-size: 8px;
  }
  .wrapper {
    padding: 5rem;
  }
}

@media (max-width: 426px) {
  .wrapper {
    padding: 0rem;
  }
}
